import emojiRegex from 'emoji-regex';

const EMOJI_REGEX = emojiRegex();

export const hasEmoji = (value) => {
  const [result] = value.match(EMOJI_REGEX) || [];
  return Boolean(result);
};

// For use with GlFormFields validators
//
// Returns true when emojis are present. Does _not_ return true for empty/null/undefined values.
export const validateNoEmoji = (fieldName) => (val) => {
  if (val === '' || val === null || val === undefined) {
    return '';
  }

  return hasEmoji(val) ? `${fieldName} can't contain emojis.` : '';
};

export const noEmoji = {
  validate: (value) => !hasEmoji(value),
  message: `{_field_} can't contain emojis.`,
};
