import Cookies from 'js-cookie';
import {
  GOOGLE_ANALYTICS_ID_COOKIE_NAME,
  TRACKING_CONTEXT_SCHEMA,
  SNOWPLOW_JS_SOURCE,
} from './constants';

export default function getStandardContext() {
  return {
    schema: TRACKING_CONTEXT_SCHEMA,
    data: {
      google_analytics_id: Cookies.get(GOOGLE_ANALYTICS_ID_COOKIE_NAME) ?? null,
      environment: window.snowplowContext?.environment,
      customer_id: window.snowplowContext?.customerId || null,
      source: SNOWPLOW_JS_SOURCE,
    },
  };
}
