import getStandardContext from './standard_context';

const dispatcher = (...data) => {
  return window.snowplow(...data);
};

const dispatcherNull = () => {
  return false;
};

const hasTracker = () => {
  return typeof window.snowplow === 'function';
};

export function initTracking() {
  if (!hasTracker()) {
    return;
  }

  window.snowplow('enableFormTracking');
  window.snowplow('enableLinkClickTracking');
  window.snowplow('enableActivityTracking', 30, 30);
  window.snowplow('trackPageView', null, [getStandardContext()]);
}

export default class Tracking {
  constructor(shouldTrack = true) {
    this.dispatchEvent = hasTracker() && shouldTrack ? dispatcher : dispatcherNull;
  }

  event(category = document.body.dataset.page, action = 'generic', data = {}) {
    if (!category) {
      throw new Error('Tracking: no category provided for tracking.');
    }

    const { label, property, value, contexts = [] } = data;

    if (value && !Number.isInteger(parseInt(value, 10))) {
      throw new Error(
        `Tracking: value must be a number or a string that can be converted to one. Received ${typeof value} for ${value} instead`,
      );
    }

    return this.dispatchEvent(
      'trackStructEvent',
      category,
      action,
      label,
      property,
      value,
      [getStandardContext()].concat(contexts),
    );
  }
}
