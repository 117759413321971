<script>
import { GlButton, GlTooltipDirective } from '@gitlab/ui';

export default {
  name: 'SidebarToggleButton',
  components: { GlButton },
  directives: {
    GlTooltip: GlTooltipDirective,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleSidebarToggle() {
      // Uses CustomEvents on window to send message to another Vue app
      // Should be migrated to in-app communication with: https://gitlab.com/gitlab-org/customers-gitlab-com/-/issues/7227
      const sidebarToggleEvent = new CustomEvent('sidebar-toggle');
      window.dispatchEvent(sidebarToggleEvent);
    },
  },
};
</script>

<template>
  <gl-button
    v-gl-tooltip.hover.right
    class="sidebar-toggle-btn"
    :aria-label="buttonText"
    :icon="buttonIcon"
    :title="buttonText"
    size="medium"
    category="tertiary"
    @click="handleSidebarToggle"
  />
</template>
