import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { apolloClient } from '~/javascripts/lib/graphql';
import Sidebar from './components/sidebar_app.vue';

Vue.use(VueApollo);

export default () => {
  const el = document.getElementById('js-sidebar');

  if (!el) {
    return false;
  }

  const apolloProvider = new VueApollo({ defaultClient: apolloClient });

  const { zuoraAccountNumber } = el.dataset;

  return new Vue({
    apolloProvider,
    el,
    provide: { zuoraAccountNumber },
    render: (createElement) => createElement(Sidebar),
  });
};
