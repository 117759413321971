// This allows us to dismiss alerts and banners that we've migrated from bootstrap
// Note: This ONLY works on elements that are created on page load

export default function initAlertHandler() {
  const DISMISSIBLE_SELECTORS = '.gl-alert, .gl-banner';
  const DISMISS_LABEL = '[aria-label="Dismiss"]';
  const DISMISS_CLASS = '.gl-alert-dismiss';
  const dismissibleElements = document.querySelectorAll(DISMISSIBLE_SELECTORS);

  dismissibleElements.forEach((element) => {
    const buttons = element.querySelectorAll(`${DISMISS_CLASS}, ${DISMISS_LABEL}`);
    if (!buttons.length) {
      return;
    }
    buttons.forEach((btn) => btn.addEventListener('click', () => element.remove()));
  });
}
