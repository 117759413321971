import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client/core';
import csrf from '~/javascripts/lib/csrf';

export const fetchPolicies = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  NO_CACHE: 'no-cache',
  CACHE_ONLY: 'cache-only',
};

const acs = [];

let pendingApolloMutations = 0;

// ### Why track pendingApolloMutations, but calculate pendingApolloRequests?
//
// In Apollo 2, we had a single link for counting operations.
//
// With Apollo 3, the `forward().map(...)` of deduped queries is never called.
// So, we resorted to calculating the sum of `inFlightLinkObservables?.size`.
// However! Mutations don't use `inFLightLinkObservables`, but since they are likely
// not deduped we can count them...
//
// https://gitlab.com/gitlab-org/gitlab/-/merge_requests/55062#note_838943715
// https://www.apollographql.com/docs/react/v2/networking/network-layer/#query-deduplication
Object.defineProperty(window, 'pendingApolloRequests', {
  get() {
    return acs.reduce(
      (sum, ac) => sum + (ac?.queryManager?.inFlightLinkObservables?.size || 0),
      pendingApolloMutations,
    );
  },
});

export function createDefaultClient(resolvers = {}, config = {}) {
  const {
    cacheConfig,
    fetchPolicy = fetchPolicies.CACHE_FIRST,
    typeDefs,
    path = '/graphql',
  } = config;
  let ac = null;

  const httpOptions = {
    uri: path,
    headers: {
      [csrf.headerKey]: csrf.token,
    },
    // fetch won’t send cookies in older browsers, unless you set the credentials init option.
    // We set to `same-origin` which is default value in modern browsers.
    // See https://github.com/whatwg/fetch/pull/585 for more information.
    credentials: 'same-origin',
  };

  const hasMutation = (operation) =>
    (operation?.query?.definitions || []).some((x) => x.operation === 'mutation');

  const requestCounterLink = new ApolloLink((operation, forward) => {
    if (hasMutation(operation)) {
      pendingApolloMutations += 1;
    }

    return forward(operation).map((response) => {
      if (hasMutation(operation)) {
        pendingApolloMutations -= 1;
      }
      return response;
    });
  });

  const httpLink = new HttpLink({ ...httpOptions });

  const appLink = ApolloLink.from([requestCounterLink, httpLink]);

  ac = new ApolloClient({
    typeDefs,
    link: appLink,
    cache: new InMemoryCache({
      ...cacheConfig,
    }),
    resolvers,
    defaultOptions: {
      query: {
        fetchPolicy,
      },
    },
  });

  acs.push(ac);

  return ac;
}

export const apolloClient = createDefaultClient();
