export const GOOGLE_ANALYTICS_ID_COOKIE_NAME = '_ga';
export const TRACKING_CONTEXT_SCHEMA = 'iglu:com.gitlab/customer_standard/jsonschema/1-0-0';
export const SNOWPLOW_JS_SOURCE = 'customers-javascript';

export const TRACKING_ACTIONS = Object.freeze({
  addNewCard: 'add_new_card',
  purchaseSubscription: 'purchase_subscription',
  changeActiveUser: 'change_active_user',
  getActivationCode: 'get_activation_code',
  copyActivationCode: 'copy_activation_code',
  renewSubscriptionSM: 'renew_subscription_sm',
  renewSubscriptionSaaS: 'renew_subscription_saas',
  buyCIMinutes: 'buy_ci_minutes',
  buyStorage: 'buy_storage_plan',
  buyDuoProSeats: 'buy_duo_pro_seats',
  changeSubscriptionRampSaaS: 'change_subscription_ramp_saas',
  changeSubscriptionRampSM: 'change_subscription_ramp_sm',
  changeSubscriptionResellerSaaS: 'change_subscription_reseller_saas',
  changeSubscriptionResellerSM: 'change_subscription_reseller_sm',
  cancelSubscriptionSaaS: 'cancel_subscription_saas',
  cancelSubscriptionSM: 'cancel_subscription_sm',
  clickButton: 'click_button',
  clickCard: 'click_card',
  clickLink: 'click_link',
  clickPurchaseSeats: 'click_purchase_seats',
  clickChange: 'click_change',
  clickModal: 'click_modal',
  clickUpgrade: 'click_upgrade',
  clickSideMenu: 'click_side_menu_item',
  successResponse: 'success_response',
  failureResponse: 'failure_response',
  updateGroup: 'update_group',
  updateSeatCount: 'update_seat_count',
  selectPaymentMethod: 'select_payment_method',
  clickTermsCheckbox: 'click_terms_checkbox',
});

export const TRACKING_CATEGORIES = Object.freeze({
  webstore: 'Webstore',
});

export const TRACKING_LABELS = Object.freeze({
  applyCouponCodeSM: 'apply_coupon_code_sm',
  applyCouponCodeFailureSM: 'apply_coupon_code_failure_sm',
  applyCouponCodeSuccessSM: 'apply_coupon_code_success_sm',
  buyStorage: 'buy_storage_plan',
  cancelSubscriptionSaaS: 'cancel_subscription_saas',
  cancelSubscriptionSM: 'cancel_subscription_sm',
  changeNamespace: 'change_namespace',
  resumeSubscriptionSaaS: 'resume_subscription_saas',
  resumeSubscriptionSM: 'resume_subscription_sm',
  renewSubscriptionSM: 'renew_subscription_sm',
  renewSubscriptionSaaS: 'renew_subscription_saas',
  buyCIMinutes: 'buy_ci_minutes',
  linkNamespace: 'link_namespace',
  linkNamespaceRamp: 'link_namespace_ramp',
  addSeatsSaaS: 'add_seats_saas',
  addSeatsSM: 'add_seats_sm',
  upgradeSubscriptionSaaS: 'upgrade_subscription_saas',
  upgradeSubscriptionSM: 'upgrade_subscription_sm',
  cancelUpdateProfile: 'cancel_update_profile',
  confirmUpdateProfile: 'confirm_update_profile',
  updateProfile: 'update_profile',
  addDuoProSaaS: 'add_duo_pro_saas',
  addDuoProSM: 'add_duo_pro_sm',
  buyDuoProSaaS: 'buy_duo_pro_saas',
  buyDuoProSM: 'buy_duo_pro_sm',
});

export const NAV_TRACKING_LABELS = Object.freeze({
  headerInfo: 'header_info',
  headerProfile: 'header_profile',
  headerLogo: 'header_logo',
  subscription: 'subscription',
  invoices: 'invoices',
  billingSettings: 'billing_account_settings',
});
